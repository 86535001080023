<template>
  <div class="custom-loading-overlay">
    <section class="centered-container">
      <img class="imgSty" src="@/assets/img/Loading.gif" />
      <p class="loading-text">{{ loadingText }}</p>
    </section>
  </div>
</template>
<script>
export default {
  props: ["loading"],
  data() {
    return {
      loadingText: this.loading,
    };
  },
  mounted() {
    console.log(this.loading, this.loadingText);
  },
};
</script>
<style slot="scoped">
.centered-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center; /* Center align text */
}
.custom-loading-overlay {
  /* background-color: rgba(255, 255, 255, 0.9); */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000; /* Higher than el-loading-mask */
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgSty {
  width: 60px;
  height: 60px;
}

.loading-text {
  margin-top: 10px; /* Space between image and text */
  font-size: 16px;
  color: #333;
}
</style>
